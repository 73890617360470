<template>
  <b-container fluid class="overflow-hidden">
    <b-row class="flex-column flex-md-row min-vh-100">
      <b-col
        class="flex-grow-0 flex-md-grow-1 py-3 d-flex flex-column  bg-primary clip-right image-size position-relative"
        :style="`background-image: url(${$images.loginBg});`"
      >
      </b-col>

      <b-col class="py-5 mx-lg-5">
        <b-container fluid style="max-width: 650px">
          <b-overlay :show="loading">
            <div v-if="gameRules">
              <!-- Greeting Card -->
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <h3 class="text-primary font-weight-bold title-text">
                      {{
                        $t("gamerules.title", { name: gameRules.first_name })
                      }}
                    </h3>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-terciary text">
                      {{ $t("gamerules.description") }}
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <b-row no-gutters>
                      <b-col
                        cols="12"
                        md="12"
                        lg="6"
                        xl="6"
                        class="d-flex align-items-center"
                      >
                        <b-icon-calendar3 class="mr-2 text-primary text" />
                        <span>
                          <small class="text-primary font-weight-bold text">
                            {{ $t("campaign_period") }}
                          </small>
                          <small class="text-terciary ml-2 text-nowrap">
                            {{ gameRules.valid_from_dt }} –
                            {{ gameRules.valid_to_dt }}
                          </small>
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                    lg="6"
                    xl="6"
                    class="d-flex align-items-center mt-0 mt-sm-1"
                  >
                    <b-icon-flag-fill class="mr-2 text-primary text" />
                    <span>
                      <small class="text-primary font-weight-bold text">
                        {{ $t("gamerules.last_report") }}
                      </small>
                      <small class="text-terciary ml-2 text-nowrap">
                        {{ gameRules.last_report_dt }}
                      </small>
                    </span>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-terciary text mb-0 mt-3">
                      {{ $t("gamerules.disclaimer") }}
                    </p>
                  </b-col>
                </b-row>
              </b-card>

              <!-- Game Rule Card -->
              <b-card class="mt-5 ">
                <b-row>
                  <b-col cols="12">
                    <h3 class="text-primary font-weight-bold title-text">
                      {{ $t("gamerules.rules.title") }}
                    </h3>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-terciary text">
                      {{
                        $t("gamerules.rules.description", {
                          points: gameRules.goal_points,
                        })
                      }}
                    </p>
                  </b-col>
                </b-row>

                <b-row
                  v-for="{ heading, text } in $t('gamerules.rules.paragraphs')"
                  :key="`${heading}\n${text}`"
                >
                  <b-col cols="12" v-if="heading">
                    <h4 class="text-primary font-weight-bold semi-title-text">
                      {{ heading }}
                    </h4>
                  </b-col>

                  <b-col cols="12">
                    <p
                      class="text-terciary text"
                      v-for="paragraph in text.split('\n')"
                      :key="paragraph"
                    >
                      {{ paragraph }}
                    </p>
                  </b-col>
                </b-row>
              </b-card>

              <b-row no-gutters class="mt-4">
                <b-col cols="12" class="d-flex justify-content-end">
                  <!--  <b-button
                    variant="primary"
                    style="border-radius:11px"
                    to="/dashboard"
                  >
                    <span class="text">{{ $t("gamerules.take_off") }}</span>
                  </b-button>-->
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios"
import { BIconFlagFill, BIconCalendar3 } from "bootstrap-vue"

export default {
  components: { BIconFlagFill, BIconCalendar3 },
  data() {
    return {
      loading: false,
      gameRules: null,
    }
  },
  methods: {
    async getGameRules() {
      this.loading = true

      try {
        const response = await axios.get("/campaign/rules")
        this.gameRules = response.data[0] ?? null
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    this.getGameRules()
  },
}
</script>

<style scoped>
.title-text {
  font-size: 14px;
}
.semi-title-text {
  font-size: 14px;
}

.text {
  font-size: 14px;
}

@media (max-width: 768px) {
  .image-size {
    min-height: 91px;
  }
}

@media (min-width: 400px) {
  .title-text {
    font-size: 16px;
  }

  .semi-title-text {
    font-size: 14px;
  }
}
@media (min-width: 800px) {
  .title-text {
    font-size: 20px;
  }
  .text {
    font-size: 14px;
  }

  .semi-title-text {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .title-text {
    font-size: 22px;
  }
  .text {
    font-size: 16px;
  }

  .semi-title-text {
    font-size: 18px;
  }
}
</style>
